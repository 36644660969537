var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":"mdi-alarm-multiple","title":(_vm.lname + ": Nächste Wettkämpfe"),"color":"primary"}},[_c('v-data-table',{attrs:{"headers":[
      { text: 'Beginn', value: 'startDate', sortable: false },
      { text: 'Liga', value: 'liga.shortName', sortable: false },
      { text: 'Mannschaften', value: 'teams', sortable: false },
      { text: 'Punkte', value: 'result', sortable: false }
    ],"items":_vm.events_filtered,"mobile-breakpoint":-1,"items-per-page":-1,"hide-default-footer":"","item-class":function (item) { return ({'stbw2023bold': item.status === 'EventMovedOnline', 'stbw2023red': item.status === 'EventStarted'}); }},on:{"click:row":_vm.open},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
    var item = ref.item;
return [(item.status === 'EventPostponed')?_c('i',[_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(_vm._f("dateformat")(item.startDate,'DD.MM.YYYY')))]),_c('br'),_vm._v("wird verschoben,"),_c('br'),_vm._v("Termin noch offen")]):_c('span',{domProps:{"innerHTML":_vm._f("dateformat")(item.startDate,'dddd<br>DD.MM.YYYY<br>HH:mm')}})]}},{key:"item.teams",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"none","white-space":"nowrap"},domProps:{"innerHTML":_vm._f("nl2br")(item.teams.map(function (t) { return t.team.name; }).join('\n'))}})]}}])}),_c('ul',[_c('li',[_c('span',{staticClass:"stbw2023bold"},[_vm._v("Fettgedruckt:")]),_vm._v(" in Vorbereitung")]),_c('li',[_c('span',{staticClass:"stbw2023red"},[_vm._v("Rot:")]),_vm._v(" Wettkampf läuft")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }